import React, { useState, useEffect } from "react";
import Tabla from "./Tabla";
import axios from "axios";

const MuestraTabla = () => {
  const [datos, setDatos] = useState([{"idusr": 1,"nombre": "HUGO A","apellido": "POLANCO","celular": "3167444170","perfil": 1,},{"idusr": 5,"nombre": "DEMO","apellido": "DYNAMIC","celular": "","perfil": 1,}]);
  const baseURL = "http://localhost:3000/clientes/";
  const tbl =
    '{"idusr": 1,"nombre": "HUGO A","apellido": "POLANCO","celular": "3167444170","perfil": 1,},{"idusr": 5,"nombre": "DEMO","apellido": "DYNAMIC","celular": "","perfil": 1,}';

  useEffect(() => {
    // Lógica para obtener el JSON de respuesta y establecerlo en el estado  
    getUsers();
  }, []);

  function getUsers() {
    axios.get(baseURL).then(function (response) {
      setDatos(response.data);
    });
  }
  return (
    <div>
      <Tabla datos={datos} />
    </div>
  );
};

export default MuestraTabla;
