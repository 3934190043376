import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { FaUserCircle } from "react-icons/fa";
import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";

const baseURL = "http://localhost:3000/ingreso";
function Login(props) {
  const navigate = useNavigate();
  const [post, setPost] = React.useState(null);
  const [email, setEmail] = useState("");
  const [clave, setClave] = useState("");

  const usernameHandler = (event) => {
    setEmail(event.target.value);
  };

  const passwordHandler = (event) => {
    setClave(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    setMensaje("");
    loginRequest();
  };

  /*
  React.useEffect(() => {
    axios.get(`${baseURL}/1`).then((response) => {
      setPost(response.data);
    });
  }, []);
*/
  function loginRequest() {
    axios
      .post(baseURL, {
        email: email,
        clave: clave,
      })
      .then((response) => {
        if (response.data.erroring > 0) {
         // setPost("Error! Por favor verifique la información ingresada");
          setPost(<Alert key="warning" variant="warning">
          Error! Por favor verifique la información ingresada
      </Alert>);
        } else {
          setPost(response.data.token);
          localStorage.setItem("token", response.data.token);
          navigate("/user/tema");
        }
      });
  }

  const [mensaje, setMensaje] = useState("");
  useEffect(() => {
    if (props.msg1 == 1) {
      //setMensaje("Ha salido del sistema!");
      setMensaje(
        <Alert key="primary" variant="primary">
          Ha salido del sistema!
        </Alert>
      );
    }
  }, []);
  //if (!post) return "No post!";
  return (
    <Container fluid className="fondo1  text-center">
      <Row className="mageninterior  ">
        <Col md={{ span: 4, offset: 4 }} className="magentop">
          <Row className="text-center boxingreso ">
            {mensaje}
            {post}
            <FaUserCircle size={100} color="gray" />
            <Form onSubmit={submitHandler}>
              <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  value={email}
                  onChange={usernameHandler}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="inputPassword5">Password</Form.Label>
                <Form.Control
                  type="password"
                  id="inputPassword5"
                  aria-describedby="passwordHelpBlock"
                  value={clave}
                  onChange={passwordHandler}
                />
              </Form.Group>
              <Form.Group>
                <Button variant="primary" type="submit">
                  Ingresar
                </Button>
              </Form.Group>
            </Form>
          </Row>
        </Col>
      </Row>
      <Row>&nbsp;</Row>
    </Container>
  );
}

export default Login;
