import { BrowserRouter, Routes, Route } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Login from "../components/Login";
import Tema from "./Tema";
import Clientes from "./Clientes";
import CotFcl from "./CotFcl";
import TemaCrearUser from "../components/TemaCrearUser";
import TemaCrearCotFcl from "../components/TemaCrearCotFcl";
import TemaEdUser from "../components/TemaEditUserr";
import Salida from "../components/Salida";
import Inicio from "../components/Inicio";
import Tablita from "../components/PrbTcli"

function Default() {
  return (
    <Container className="">
      <BrowserRouter>
        <Routes>
          <Route index element={<Login />} />
          <Route path="user/tema" element={<Tema />} />
          <Route path="user/clientes" element={<Clientes />} />
          <Route path="user/cotfcl" element={<CotFcl />} />
          <Route path="user/crearusr" element={<TemaCrearUser />} />
          <Route path="user/crearcotfcl" element={<TemaCrearCotFcl />} />
          <Route path="user/:id/editarusr" element={<TemaEdUser />} />
          <Route path="user/inicio" element={<Inicio />} />
          <Route path="user/tablita" element={<Tablita />} />
          <Route path="user/salir" element={<Salida />} />
        </Routes>
      </BrowserRouter>
    </Container>
  );
}
export default Default;
