import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import { Link, useNavigate } from "react-router-dom";
import CantidadContenedores from "./CantidadContenedores";
import DetallesCot from "./DetallesCot";

function CreateCotFcl() {
  const baseURL = "http://localhost:3000/nuevousr";
  const lClientesURL = "http://localhost:3000/listados/lclientes";
  const lProveedoresURL = "http://localhost:3000/listados/lproveedor";
  const lPaisesURL = "http://localhost:3000/listados/lpaises";
  const lComercialesURL = "http://localhost:3000/listados/lcomerciales";
  const [totcampos, setTotcampos] = useState(0);
  const navigate = useNavigate();
  const [lClientes, setLClientes] = useState([]);
  const [lProveedores, setLProveedores] = useState([]);
  const [lPaises, setLPaises] = useState([]);
  const [lCidades, setLCiudades] = useState([]);
  const [lCidadesD, setLCiudadesD] = useState([]);
  const [lComerciales, setLComerciales] = useState([]);
  const [componentes, setComponentes] = useState([]);

  const lIncoterm = [
    { id: 1, nombre: "EXW" },
    { id: 2, nombre: "FCA" },
    { id: 3, nombre: "FAS" },
    { id: 4, nombre: "FOB" },
  ];
  const lCampos = [
    { idc: 1, nombre: "Abierto" },
    { idc: 2, nombre: "BAF" },
    { idc: 3, nombre: "THC" },
    { idc: 4, nombre: "BL" },
    { idc: 5, nombre: "DUE CARRIER" },
    { idc: 6, nombre: "DELIVERY" },
    { idc: 7, nombre: "THC" },
    { idc: 8, nombre: "DUE AGENT" },
  ];

  useEffect(() => {
    getlclientes();
    getlproveedores();
    getlpaises();
    getcomerciales();
  }, []);
  function getlclientes() {
    axios.get(lClientesURL).then(function (response) {
      setLClientes(response.data);
    });
  }

  function addCampo() {
    setTotcampos(totcampos + 1);
    const nuevoComponente = (
      <DetallesCot
        id={totcampos}
        key={totcampos}
        eliminarComponente={eliminarComponente}
      />
    );
    setComponentes([...componentes, nuevoComponente]);
  }

  const eliminarComponente = (indice) => {
    const nuevosComponentes = [...componentes];
    console.log("idice: " + indice);
    console.log("arr: " + nuevosComponentes);
    nuevosComponentes.slice(indice);
    setComponentes(nuevosComponentes);
  };

  function getlproveedores() {
    axios.get(lProveedoresURL).then(function (response) {
      setLProveedores(response.data);
    });
  }

  function getcomerciales() {
    axios.get(lComercialesURL).then(function (response) {
      setLComerciales(response.data);
    });
  }

  function getlpaises() {
    axios.get(lPaisesURL).then(function (response) {
      setLPaises(response.data);
    });
  }

  function getciudades(pais) {
    axios.get(lPaisesURL + "/" + pais).then(function (response) {
      setLCiudades(response.data);
    });
  }

  function getciudadesD(pais) {
    axios.get(lPaisesURL + "/" + pais).then(function (response) {
      setLCiudadesD(response.data);
    });
  }
  const paisHandler = (event) => {
    getciudades(event.target.value);
  };

  const paisDHandler = (event) => {
    getciudadesD(event.target.value);
  };

  const [post, setPost] = React.useState(null);
  const [diasLibres, setDiasLibres] = useState("");
  const [trm, setTrm] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [ciudadcot, setCiudadcot] = useState("");
  const [contacto, setContacto] = useState("");
  const [vtarifa, setVtarifa] = useState("");
  const [frecuencia, setFrecuencia] = useState("");
  const [ttransito, setTtransito] = useState("");
  const [utiltcont, setUtilCont] = useState({
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
  });
  const [utilFinalCont, setUtilFinalCont] = useState(0);
  const [qtytCont, setQtyCont] = useState({
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
  });
  const [qtyFinalCont, setQtyFinalCont] = useState(0);
  const [teus, setTeus] = useState(0);

  function actualizarUtilcont(valor, pos) {
    //setUtilCont(utiltcont[2]=parseInt(valor));
    const nuevoaarutil = utiltcont;
    nuevoaarutil[pos] = valor;
    setUtilCont(nuevoaarutil);
    let suma = 0;
    suma = nuevoaarutil[1] + nuevoaarutil[2] +  nuevoaarutil[3] + nuevoaarutil[4] + nuevoaarutil[5] + nuevoaarutil[6] + nuevoaarutil[7] + nuevoaarutil[8] + nuevoaarutil[9] ;
    setUtilFinalCont(suma);
  }


  function actualizarQtycont(valor, pos) {
    //setUtilCont(utiltcont[2]=parseInt(valor));
    const nuevoaarcont = qtytCont;
    nuevoaarcont[pos] = valor;
    setQtyCont(nuevoaarcont);
    let suma = 0;
    let sumat =0;
    suma = nuevoaarcont[1] + nuevoaarcont[2] +  nuevoaarcont[3] + nuevoaarcont[4] + nuevoaarcont[5] + nuevoaarcont[6] + nuevoaarcont[7] + nuevoaarcont[8] + nuevoaarcont[9] ;
    if (pos >=5){
      sumat = teus + (nuevoaarcont[pos] * 2);
    }else{
      sumat = teus + nuevoaarcont[pos];
    }
    setQtyFinalCont(suma);
    setTeus(sumat);
  }
  const ciudadcotHandler = (event) => {
    setCiudadcot(event.target.value);
  };
  const contactoHandler = (event) => {
    setContacto(event.target.value);
  };

  const trmHandler = (event) => {
    setTrm(event.target.value);
  };

  const vtarifaHandler = (event) => {
    setVtarifa(event.target.value);
  };

  const diaslibresHandler = (event) => {
    setDiasLibres(event.target.value);
  };

  const frecuenciaHandler = (event) => {
    setFrecuencia(event.target.value);
  };

  const ttransitoHandler = (event) => {
    setTtransito(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    setMensaje("");
    insertRequest();
  };

  function insertRequest() {
    axios
      .post(baseURL, {
        ciudadcot: ciudadcot,
        contacto: contacto,
        diaslibres: diasLibres,
        trm: trm,
        vtarifa: vtarifa,
        frecuencia: frecuencia,
        ttransito: ttransito,
      })
      .then((response) => {
        if (response.data.erroring > 0) {
          // setPost("Error! Por favor verifique la información ingresada");
          setPost(
            <Alert key="warning" variant="warning">
              Error! Por favor verifique la información ingresada
            </Alert>
          );
        } else {
          setPost(
            <Alert key="success" variant="success">
              Usuario creado con Exito, ID: {response.data.insertId}
            </Alert>
          );
          navigate("/user/tema");
        }
      });
  }
  return (
    <Container fluid>
      <Form onSubmit={submitHandler}>
        {post}
        <Form.Group>
          <Row>
            <Col className="mb-6">
              <Form.Label htmlFor="cliente">Cliente</Form.Label>
              <Form.Select name="cliente" id="cliente" required>
                <option value="">Selecciones Una Opción</option>
                {lClientes.map((opcion) => (
                  <option key={opcion.idcliente} value={opcion.idcliente}>
                    {opcion.nombre}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col className="mb-6">
              <Form.Label className="mb-2 ">Ciudad</Form.Label>
              <Form.Control
                name="ciudadcot"
                type="text"
                placeholder="Ciudad"
                required
                value={ciudadcot}
                onChange={ciudadcotHandler}
              />
            </Col>
          </Row>
        </Form.Group>

        <Row>
          <Col>&nbsp;</Col>
        </Row>
        <Form.Group className="mb-3">
          <Row>
            <Col>
              <Form.Label>Contacto</Form.Label>
              <Form.Control
                name="contacto"
                type="text"
                placeholder="Contacto"
                value={contacto}
                onChange={contactoHandler}
              />
            </Col>
            <Col className="mb-6">
              <Form.Label>Transportador</Form.Label>
              <Form.Select name="transportador" required>
                <option value="">Selecciones Una Opción</option>
                {lProveedores.map((opcion) => (
                  <option
                    key={opcion.id_transportador}
                    value={opcion.id_transportador}
                  >
                    {opcion.transportador}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </Form.Group>

        <Row>
          <Col>&nbsp;</Col>
        </Row>
        <Form.Group className="mb-3">
          <Row>
            <Col className="mb-6">
              <Form.Label>Pais Origen</Form.Label>
              <Form.Select name="pais" required onChange={paisHandler}>
                <option value="">Selecciones Una Opción</option>
                {lPaises.map((opcion) => (
                  <option key={opcion.COUNTRY_ID} value={opcion.COUNTRY_ID}>
                    {opcion.COUNTRY_DESC}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col>
              <Form.Label>Ciudad Origen</Form.Label>
              <Form.Select required name="ciudad">
                <option value="">Selecciones Una Opción</option>
                {lCidades.map((opcion) => (
                  <option key={opcion.LOCATION_ID} value={opcion.LOCATION_ID}>
                    {opcion.LOCATION_DESC}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </Form.Group>

        <Row>
          <Col>&nbsp;</Col>
        </Row>
        <Form.Group className="mb-3">
          <Row>
            <Col className="mb-6">
              <Form.Label>Pais Destino</Form.Label>
              <Form.Select name="paisD" required onChange={paisDHandler}>
                <option value="">Selecciones Una Opción</option>
                {lPaises.map((opcion) => (
                  <option key={opcion.COUNTRY_ID} value={opcion.COUNTRY_ID}>
                    {opcion.COUNTRY_DESC}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col>
              <Form.Label>Ciudad Destino</Form.Label>
              <Form.Select required name="ciudadD">
                <option value="">Selecciones Una Opción</option>
                {lCidadesD.map((opcion) => (
                  <option key={opcion.LOCATION_ID} value={opcion.LOCATION_ID}>
                    {opcion.LOCATION_DESC}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </Form.Group>

        <Row>
          <Col>&nbsp;</Col>
        </Row>
        <Form.Group className="mb-3">
          <Row>
            <Col>
              <Form.Label>Días Libres</Form.Label>
              <Form.Control
                name="diaslibres"
                type="number"
                value={diasLibres == "" ? 0 : diasLibres}
                onChange={diaslibresHandler}
              />
            </Col>
            <Col className="mb-6">
              <Form.Label>Comercial</Form.Label>
              <Form.Select name="comercial" required>
                <option value="">Selecciones Una Opción</option>
                {lComerciales.map((opcion) => (
                  <option key={opcion.id_comercial} value={opcion.id_comercial}>
                    {opcion.comercial}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col>
              <Form.Label>TRM</Form.Label>
              <Form.Control
                name="trm"
                type="number"
                value={trm}
                onChange={trmHandler}
              />
            </Col>
          </Row>
        </Form.Group>

        <Row>
          <Col>&nbsp;</Col>
        </Row>
        <Form.Group>
          <Row>
            <Col className="mb-6">
              <Form.Label>Drop-Off</Form.Label>
              <br />
              <Form.Check
                inline
                label="SI"
                name="dropoff"
                type="radio"
                id={1}
              />
              <Form.Check
                inline
                label="NO"
                name="dropoff"
                type="radio"
                id={2}
              />
            </Col>
            <Col className="mb-6">
              <Form.Label>Incoterm</Form.Label>
              <Form.Select name="incoterm" required>
                <option value="">Selecciones Una Opción</option>
                {lIncoterm.map((opcion) => (
                  <option key={opcion.id} value={opcion.nombre}>
                    {opcion.nombre}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col className="mb-6">
              <Form.Label className="mb-2 ">Vigencia Tarifa:</Form.Label>
              <Form.Control
                name="vtarifa"
                type="date"
                placeholder="Vigencia Tarifa"
                required
                value={vtarifa}
                onChange={vtarifaHandler}
              />
            </Col>
          </Row>
        </Form.Group>

        <Row>
          <Col>&nbsp;</Col>
        </Row>
        <Form.Group>
          <Row>
            <Col className="mb-6">
              <Form.Label className="mb-2 ">Frecuencia:</Form.Label>
              <Form.Control
                name="frecuencia"
                type="text"
                placeholder="Frecuencia"
                required
                value={frecuencia}
                onChange={frecuenciaHandler}
              />
            </Col>
            <Col className="mb-6">
              <Form.Label className="mb-2 ">Tiempo de Transito:</Form.Label>
              <Form.Control
                name="ttransito"
                type="text"
                placeholder="Tiempo de Transito"
                required
                value={ttransito}
                onChange={ttransitoHandler}
              />
            </Col>
          </Row>
        </Form.Group>
        <Row>
          <Col>&nbsp;</Col>
        </Row>
        <Row>
          <Col className="titcampos">Datos Contenedores</Col>
        </Row>
        <Row>
          <Col>&nbsp;</Col>
        </Row>
        <CantidadContenedores
          key={1}
          nombre="c20"
          actualizarUtilcont={actualizarUtilcont}
          actualizarQtycont={actualizarQtycont}
          pos={1}
        />
        <CantidadContenedores
          key={2}
          nombre="c20rf"
          actualizarUtilcont={actualizarUtilcont}
          actualizarQtycont={actualizarQtycont}
          pos={2}
        />
        <CantidadContenedores
          key={3}
          nombre="c20ot"
          actualizarUtilcont={actualizarUtilcont}
          actualizarQtycont={actualizarQtycont}
          pos={3}
        />
        <CantidadContenedores
          key={4}
          nombre="c20fr"
          actualizarUtilcont={actualizarUtilcont}
          actualizarQtycont={actualizarQtycont}
          pos={4}
        />
        <CantidadContenedores
          key={5}
          nombre="c40"
          actualizarUtilcont={actualizarUtilcont}
          actualizarQtycont={actualizarQtycont}
          pos={5}
        />
        <CantidadContenedores
          key={6}
          nombre="c40hq"
          actualizarUtilcont={actualizarUtilcont}
          actualizarQtycont={actualizarQtycont}
          pos={6}
        />
        <CantidadContenedores
          key={7}
          nombre="c40rf"
          actualizarUtilcont={actualizarUtilcont}
          actualizarQtycont={actualizarQtycont}
          pos={7}
        />
        <CantidadContenedores
          key={8}
          nombre="c40ot"
          actualizarUtilcont={actualizarUtilcont}
          actualizarQtycont={actualizarQtycont}
          pos={8}
        />
        <CantidadContenedores
          key={9}
          nombre="c40fr"
          actualizarUtilcont={actualizarUtilcont}
          actualizarQtycont={actualizarQtycont}
          pos={9}
        />

        <Form.Group>
          <Row>
            <Col className="mb-6">
              <Form.Label className="mb-2 ">Total Contenedores</Form.Label>
              <Form.Control name="totContenedores" type="number" disabled value={qtyFinalCont} />
            </Col>
            <Col className="mb-6">
              <Form.Label className="mb-2 ">Total TEUS</Form.Label>
              <Form.Control name="totTeus" type="number" disabled value={teus} />
            </Col>
            <Col>&nbsp;</Col>
            <Col className="mb-6">
              <Form.Label className="mb-2 ">Total Utilidad</Form.Label>
              <Form.Control
                name="totUtilidad"
                type="number"
                disabled
                value={utilFinalCont}
              />
            </Col>
          </Row>
        </Form.Group>
        <Col>&nbsp;</Col>
        <Row>
          <Col className="titcampos">Tipo Campo</Col>
        </Row>
        <Col>&nbsp;</Col>
        <Form.Group>
          <Row>
            <Col className="mb-6">
              <Form.Select name="adicionarCampo" required>
                <option value="">Selecciones Una Opción</option>
                {lCampos.map((opcion) => (
                  <option key={opcion.idc} value={opcion.idc}>
                    {opcion.nombre}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col>
              <Button className="btn btn-success" onClick={() => addCampo()}>
                {" "}
                +{" "}
              </Button>
            </Col>
            <Col>&nbsp;</Col>
          </Row>
          <Col>&nbsp;</Col>
        </Form.Group>
        {componentes}
        <Col>&nbsp;** {totcampos}</Col>
        <Form.Group className="mb-3">
          <Button variant="primary" type="submit">
            Enviar
          </Button>
        </Form.Group>
      </Form>
    </Container>
  );
}

export default CreateCotFcl;
