import React from "react";
import Tablita from "./MuestraTabla"


const PrbTcli = ()=>{
    return (
        <div>
          <h1>Mi Tabla!!</h1>
          <Tablita />
        </div>
      );
}

export default PrbTcli;