import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import { Link, useNavigate } from "react-router-dom";

function CreateUser() {
  const baseURL = "http://localhost:3000/nuevousr";
  const [mensaje, setMensaje] = useState("");
  const navigate = useNavigate();

  const [post, setPost] = React.useState(null);
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [email, setEmail] = useState("");
  const [clave, setClave] = useState("");

  const nombreHandler = (event) => {
    setNombre(event.target.value);
  };
  const apellidoHandler = (event) => {
    setApellido(event.target.value);
  };
  const emailHandler = (event) => {
    setEmail(event.target.value);
  };

  const clavedHandler = (event) => {
    setClave(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    setMensaje("");
    insertRequest();
  };

  function insertRequest() {
    axios
      .post(baseURL, {
        nombre: nombre,
        apellido: apellido,
        email: email,
        clave: clave,
      })
      .then((response) => {
        if (response.data.erroring > 0) {
          // setPost("Error! Por favor verifique la información ingresada");
          setPost(
            <Alert key="warning" variant="warning">
              Error! Por favor verifique la información ingresada
            </Alert>
          );
        } else {
          setPost(
            <Alert key="success" variant="success">
              Usuario creado con Exito, ID: {response.data.insertId}
            </Alert>
          );
          navigate("/user/tema");
        }
      });
  }
  return (
    <Container fluid>
      <Form onSubmit={submitHandler}>
        {post}
        <Form.Group className="mb-3">
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            name="Nombre"
            type="text"
            placeholder="Nombre"
            value={nombre}
            onChange={nombreHandler}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Apellido</Form.Label>
          <Form.Control
            name="Apellido"
            type="text"
            placeholder="Apellido"
            value={apellido}
            onChange={apellidoHandler}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="name@example.com"
            value={email}
            onChange={emailHandler}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Select aria-label="Default select example">
            <option>Seleccione el País</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="inputPassword5">Password</Form.Label>
          <Form.Control
            type="password"
            id="inputPassword5"
            aria-describedby="passwordHelpBlock"
            value={clave}
            onChange={clavedHandler}
          />
          <Form.Text id="passwordHelpBlock" muted>
            Your password must be 8-20 characters long, contain letters and
            numbers, and must not contain spaces, special characters, or emoji.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3">
          <Button variant="primary" type="submit">
            Enviar
          </Button>
        </Form.Group>
      </Form>
    </Container>
  );
}

export default CreateUser;
