import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import react, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";


export default function EditUser() {
  const navigate = useNavigate();
  
  const baseURL = "http://localhost:3000/prueba1/";
  const [mensaje, setMensaje] = useState("");
  const [usuarios, setUsuarios] = useState([]);

  const [inputs, setInputs] = useState([]);
  const { id } = useParams();
  //console.log(id);

  const [post, setPost] = useState(null);
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [email, setEmail] = useState("");
  const [idusr, setIdusr] = useState("");
  const [isEnabled, setIsEnabled] = useState(-1);

  const nombreHandler = (event) => {
    setNombre(event.target.value);
  };
  
 
  const apellidoHandler = (event) => {
    setApellido(event.target.value);
  };
  const emailHandler = (event) => {
    setEmail(event.target.value);
  };


  const submitHandler = (event) => {
    event.preventDefault();
    setMensaje("");
    updateRequest();
  };

  
  useEffect( () => {
     userDetails(id);
  }, []);

const userDetails = async (id)=> {

const fetchUser = async () => {
  try {
    const response = await axios.get(baseURL + id); 
    console.log("ccc: "+ JSON.stringify(response.data))
    //setUsuarios(response.data);
    //const newObj = {usuarios, ...response};
    setUsuarios( response.data[0]);
    setNombre(response.data[0].nombre);
    setApellido(response.data[0].apellido);
    setEmail(response.data[0].email);
    setIdusr(response.data[0].idusr);
    //console.log("sss: "+ JSON.stringify(usuarios))
    //console.log("xxx: "+ JSON.stringify(newObj))
  } catch (error) {
    console.error(error);
  }
};

fetchUser();


}

  function updateRequest() {
    axios
      .put(baseURL, {
        nombre: nombre,
        apellido: apellido,
        email: email,
        idusr: idusr,
        isEnabled: isEnabled,
      })
      .then((response) => {
        if (response.data.erroring > 0) {
          // setPost("Error! Por favor verifique la información ingresada");
          setPost(
            <Alert key="warning" variant="warning">
              Error! Por favor verifique la información ingresada
            </Alert>
          );
        } else {
          setPost(
            <Alert key="success" variant="success">
              Usuario actualizado con Exito, ID: {response.data.insertId}
            </Alert>
          );
          navigate("/user/tema");
        }
      });
  }



  return (
    <Container fluid>
      {post}
      <Form onSubmit={submitHandler}>
        <Form.Group className="mb-3" controlId="formNombre">
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            name="Nombre"
            type="text"
            value = {nombre }
            placeholder="Nombre"
            onChange={nombreHandler}
          />
        </Form.Group >
        <Form.Group className="mb-3" controlId="formApellido">
          <Form.Label>Apellido</Form.Label>
          <Form.Control
            name="Apellido"
            type="text"
            placeholder="Apellido"
            value={apellido }
            onChange={apellidoHandler}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="name@example.com"
            value={email}
            onChange={emailHandler}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Select aria-label="Default select example">
            <option>Seleccione el País</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>
        </Form.Group>

          <Button variant="primary" type="submit">
            Enviar
          </Button>
      </Form>
    </Container>
 );
}
