import { react } from "react";
import { Link, useNavigate } from "react-router-dom";
import Login from "../components/Login";

function Salida() {
    const navigate = useNavigate();
      localStorage.removeItem("token");
      //navigate("/");
      return(
        <Login msg1={1}/>
      );
}

export default Salida;
