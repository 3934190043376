import React, { useState, useEffect } from "react";

function Inicio() {
  const [user, setUser] = useState("HUGOAA");
  const [message, setMessage] = useState("");



  useEffect(() => {
    async function handleProtected() {
      const idToken = localStorage.getItem("token");
      console.log("ttkk--" + idToken);
      fetch("http://localhost:3000/acceso", {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      })
        .then((response) => response.json())
        .then((data) => setMessage(data.nombre))
        .catch((error) => console.error(error));
    }

    handleProtected();
  }, []);

  return (
    <>
      HOLA: {user}
      <div>MSG: {message}</div>
    </>
  );
}
export default Inicio;
