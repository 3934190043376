import axios from "axios";
import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import Alert from "react-bootstrap/Alert";
import swal from "sweetalert";

export default function ListUser() {
  const [users, setUsers] = useState([]);
  const [mensaje, setMensaje] = useState();
  const [escom, setEscom] = useState("No");
  const [pactual, setPactual] = useState(0);
  const baseURL = "http://localhost:3000/prueba1/";
  var itemsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    getUsers();
  }, []);
  function getUsers() {
    axios.get(baseURL).then(function (response) {
      setUsers(response.data);
    });
  }
  const tamanoTabla = Object.keys(users).length;
  const npaginas = Math.ceil(tamanoTabla / itemsPerPage);

  let active = pactual + 1;
  let items = [];
  for (let number = 1; number <= npaginas; number++) {
    items.push(
      <Pagination.Item
        size="sm"
        key={number}
        active={number === active}
        onClick={() => {
          handlePageChange(number - 1);
        }}
      >
        {number}
      </Pagination.Item>
    );
  }

  function updateRequest(isEnabled, idusr) {
    let msg1= "";
    let msg2= "";
    let msg3="";
    if (isEnabled){
      msg1= "Está seguro de Activar usuario?";
      msg2= "Se dispone a Activar el usuario!";
      msg3="OK! El usuario ha sido Activado!";
    }else{
      msg1= "Está seguro de Inactivar usuario?";
      msg2= "Se dispone a Inactivar el usuario!";
      msg3="OK! El usuario ha sido Inactivado!";
    }
    swal({
      title: msg1,
      text: msg2,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios
          .put(baseURL, {
            isEnabled: isEnabled,
            idusr: idusr,
          })
          .then((response) => {
            if (response.data.erroring > 0) {
              // setPost("Error! Por favor verifique la información ingresada");
              setMensaje(
                <Alert key="warning" variant="warning">
                  Error! Por favor verifique la información ingresada
                </Alert>
              );
            } else {
              setMensaje(
                <Alert key="success" variant="success">
                  Usuario actualizado con Exito, ID: {response.data.insertId}
                </Alert>
              );
              //navigate("/user/tema");
            }
          });
        swal(msg3, {
          icon: "success",
        });
      } else {
        swal("La operación ha sido cancelada!");
      }
    });
  }

  const borrarRegistro = (idusr) => {
    swal({
      title: "Está seguro de eliminar el usuario?",
      text: "una vez elimiado se perdera el ingreso de este al sistema!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios
          .delete("http://localhost:3000/prueba1/" + idusr)
          .then(function (response) {
            if (response.data.erroring > 0) {
              // setPost("Error! Por favor verifique la información ingresada");
              setMensaje(
                <Alert key="warning" variant="warning">
                  Error! no se pudo borrar el usuario
                </Alert>
              );
            } else {
              //setMensaje(response.data.token);
              //navigate("/user/tema");
              setMensaje(
                <Alert key="success" variant="success">
                  Usuario borrado con Exito.
                </Alert>
              );
            }
          });
        swal("OK! El usuario ha sido eliminado!", {
          icon: "success",
        });
      } else {
        swal("La operación ha sido cancelada!");
      }
    });
  };

  const handlePageChange = (selected) => {
    setPactual(selected);
    setCurrentPage(selected + 1);
  };
  const handlePageChange1 = (move) => {
    if (move == 1) {
      setCurrentPage(pactual + 2);
      setPactual(pactual + 1);
    } else {
      setCurrentPage(pactual);
      setPactual(pactual - 1);
    }
  };
  const usersToShow = users.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <Container>
      <Row>&nbsp;{mensaje}</Row>
      <Row>
        <Col>
          <h2>Módulo Usuarios</h2>
        </Col>
        <Col align="end">
          <Button href="/user/crearusr">Nuevo Usuario</Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Nombre</th>
                <th>Email</th>
                <th>Activo</th>
              </tr>
            </thead>
            <tbody>
              {usersToShow.map((user, key) => (
                <tr key={key}>
                  <td>{user.idusr}</td>
                  <td>
                    {user.nombre} {user.apellido}
                  </td>
                  <td>{user.email}</td>
                  <td>{user.activo ? "Si" : "No"}</td>
                  <td>
                    <Button
                      href={`${user.idusr}/editarusr`}
                      variant="warning"
                      size="sm"
                    >
                      {" "}
                      Editar{" "}
                    </Button>{" "}
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => {
                        borrarRegistro(user.idusr);
                      }}
                    >
                      {" "}
                      Borrar{" "}
                    </Button>{" "}
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => {
                        updateRequest(!user.activo, user.idusr);
                      }}
                    >
                      {user.activo ? "Desactivar" : "Activar"}{" "}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <div>
        <Pagination>
          <Pagination.First
            size="sm"
            onClick={() => {
              handlePageChange(0);
            }}
          />
          <Pagination.Prev
            size="sm"
            onClick={() => {
              handlePageChange1(0);
            }}
          />
          <Pagination size="sm">{items}</Pagination>
          <Pagination.Next
            size="sm"
            onClick={() => {
              handlePageChange1(1);
            }}
          />
          <Pagination.Last
            size="sm"
            onClick={() => {
              handlePageChange(npaginas - 1);
            }}
          />
        </Pagination>
      </div>
    </Container>
  );
}
