import { Container, Row, Col, Image, Nav, NavDropdown } from "react-bootstrap";
import Logo from "../assets/logo200.jpg";

export default function MenuLateral() {
  return (
    <Container fluid>
      <Row>
        <Col className="text-center my-3">
          <Image src={Logo} alt="Logo" fluid />
        </Col>
      </Row>
      <Row>
        <Nav defaultActiveKey="/home" className="flex-column" variant="pills">
          <Nav.Link href="/home">Inicio</Nav.Link>

          <Nav.Item>
            <NavDropdown title="Clientes" id="basic-nav-dropdown">
              <NavDropdown.Item href="/user/clientes">
                Listar Clientes
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Usuarios" id="basic-nav-dropdown">
              <NavDropdown.Item href="/user/tema">
                Listar Usuario
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/user/crearusr">
                Crear Usuario
              </NavDropdown.Item>
            </NavDropdown>
          </Nav.Item>
          <Nav.Link href="/user/salir">Salir</Nav.Link>
        </Nav>
      </Row>
    </Container>
  );
}
