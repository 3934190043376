import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../assets/fondoOscuro.png"

export default function MenuSup(props) {
  return (
 
    <Navbar bg="dark" variant="dark" >
    <Container>
      <Navbar.Brand href="#home">
        <img
          alt=""
          src={Logo}
          height="30"
          className="d-inline-block align-top"
        />{' - '}
        Coltegra Cargo
      </Navbar.Brand>
      
      <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            Signed in as: <a href="#login">{props.nombre}</a>
          </Navbar.Text>
        </Navbar.Collapse>
    </Container>
  </Navbar>
  );
}
