import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import { Link, useNavigate } from "react-router-dom";

function DetallesCot(props) {
    const handleEliminar = () => {
        props.eliminarComponente(props.id);
      };
    
  return (
    <>
      <Form.Group>
        <Row>
          <Col className="mb-6 col-1">
            <Button className="btn btn-xs btn-success">+</Button>
          </Col>
          <Col className="mb-6">
            <Form.Select name={"vldFormula" + props.id}>
              <option key="0"  value="">Selecciones Una Opción</option>
              <option key="1" value="1">Cont</option>
              <option key="2"  value="2">TEUS</option>
              <option key="3"  value="3">LS</option>
            </Form.Select>
          </Col>
          <Col className="mb-6 col-3">
            <Form.Control
              name={"Vlconcept" + props.id}
              type="text"
              placeholder="Concepto"
              required
            />
          </Col>
          <Col className="mb-6">
            <Form.Select name={"VldMoneda" + props.id}>
              <option key="0"  value="">Selecciones Una Moneda</option>
              <option key="1"  value="1">USD</option>
              <option key="2"  value="2">COP</option>
            </Form.Select>
          </Col>
          <Col className="mb-6 col-2">
            <Form.Control
              name={"Vldv" + props.id}
              type="number"
              placeholder="Venta"
              required
            />
          </Col>
          <Col className="mb-6 col-2">
            <Form.Control
              name={"Vldc" + props.id}
              type="number"
              placeholder="Compra"
              required
            />
          </Col>
          <Col className="mb-6">
            <Form.Select name={"VldTipo" + props.id}>
              <option key="0"  value="">Selecciones Una Tipo</option>
              <option key="1"  value="1">ORIGEN</option>
              <option key="2"  value="2">DESTINO</option>
            </Form.Select>
          </Col>
          <Col>
            <button className="btn btn-danger" onClick={handleEliminar}>-</button>
          </Col>
        </Row>
      </Form.Group>
      <Row>
        <Col>&nbsp;</Col>
      </Row>

      <Form.Group>
        <Row>
          <Col className="mb-6 col-1">Venta USD</Col>
          <Col className="mb-6 col-2">
            <Form.Control
              name={"Vldtvusd" + props.id}
              type="number"
              placeholder="Compra"
              disabled
              value={0}
            />
          </Col>
          <Col className="mb-6 col-1">Venta COP</Col>
          <Col className="mb-6 col-2">
            <Form.Control
              name={"Vldtvcop" + props.id}
              type="number"
              placeholder="Compra"
              disabled
              value={0}
            />
          </Col>
          <Col className="mb-6 col-1">Compra USD</Col>
          <Col className="mb-6 col-2">
            <Form.Control
              name={"Vldtcusd" + props.id}
              type="number"
              placeholder="Compra"
              disabled
              value={0}
            />
          </Col>
          <Col className="mb-6 col-1">Compra COP</Col>
          <Col className="mb-6 col-2">
            <Form.Control
              name={"Vldtccop" + props.id}
              type="number"
              placeholder="Compra"
              disabled
              value={0}
            />
          </Col>
        </Row>
      </Form.Group>
      <Form.Group>
        <Row className="bg-light">
          <Col className="mb-6 col-8">&nbsp;</Col>
          <Col className="mb-6 col-2">Utilidad USD</Col>
          <Col className="mb-6 col-2">
            <Form.Control
              name={"Vldtutil" + props.id}
              type="number"
              placeholder="Compra"
              disabled
              value={0}
            />
          </Col>
        </Row>
      </Form.Group>
      <Row>
        <Col>&nbsp;</Col>
      </Row>
    </>
  );
}
export default DetallesCot;
