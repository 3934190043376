import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import EditUser from "../components/EditUser";
import MenuLateral from "../components/MenuLateral";
import MenuSup from "../components/MenuSup";
import Pie from "../components/Pie";
import jwt from "jwt-decode";
import { useEffect, useState } from "react";

var siTkn = 0;
function TemaEditUserr() {
  const [usuario, setUsuario] = useState({ nombre: "nn" });
  const [cargandoUsuario, setCargandoUsuario] = useState(true);
  const [respag, setRespag] = useState("<>No Tiene aceso</>");

  async function getTkn(tkn) {
    const userProfile = await jwt(tkn);
    return userProfile;
  }

  useEffect(() => {
    async function cargarUsuario() {
      const idToken = localStorage.getItem("token");
      if (idToken == null) {
        console.log("No tkn1_ " + siTkn);
        setCargandoUsuario(false);
        setRespag("No hay Acceso");
        return;
      }
      try {
        const userProfile1 = await getTkn(idToken);
        setUsuario(userProfile1);
        console.log("nombre2: " + userProfile1.nombre);
        //const {data : usuario } = userProfile.nombre;
        setCargandoUsuario(false);
        setRespag(
          <Container>
            {" "}
            <Row>
              {" "}
              <Col xs={2} className="sidebar">
                {" "}
                <MenuLateral />{" "}
              </Col>{" "}
              <Col xs={10}>
                {" "}
                <Row>
                  {" "}
                  <MenuSup
                    nombre={userProfile1.nombre + " " + userProfile1.apellido}
                  />{" "}
                </Row>{" "}
                <Row>
                  <EditUser />{" "}
                </Row>{" "}
                <Row>
                  {" "}
                  <Pie />{" "}
                </Row>{" "}
              </Col>{" "}
            </Row>{" "}
          </Container>
        );
      } catch (error) {
        console.log("error " + error);
      }
    }

    cargarUsuario();
  }, []);
  return (
    <>
      {respag}
    </>
  );
}
export default TemaEditUserr;
