import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import { useState, useEffect } from "react";

function CantidadContenedores(props) {
  const [qty, setQty] = useState(0);
  const [compra, setCompra] = useState(0);
  const [venta, setVenta] = useState(0);
  const [utilidad, setUtilidad] = useState(0);
  const [utilTotal, setUtilTotal] = useState(0);

 
  const qtyHandler = (event) => {
    setQty(event.target.value);
    utilidadcont(compra, venta, event.target.value);
    props.actualizarQtycont(parseInt(event.target.value), props.pos);
  };
  const compraHandler = (event) => {
    setCompra(event.target.value);
    utilidadcont(event.target.value, venta, qty);
  };

  const ventaHandler = (event) => {
    setVenta(event.target.value);
    utilidadcont(compra, event.target.value, qty);
  };

  const utilidadtotal = (valor) => {
    setUtilTotal(utilTotal + valor);
  };

  const utilidadcont = (c, v, q) => {
    setUtilidad((v - c) * q);
    utilidadtotal((v - c) * q);
    props.actualizarUtilcont(((v - c) * q), props.pos);
  };

  return (
    <>
      <Form.Group>
        <Row>
          <Col className="mb-6">
            <Form.Label className="mb-2 "># {props.nombre}</Form.Label>
            <Form.Control
              name={props.nombre}
              type="number"
              required
              onChange={qtyHandler}
              value={qty}
            />
          </Col>
          <Col className="mb-6">
            <Form.Label className="mb-2 ">Compra</Form.Label>
            <Form.Control
              name={"Vlc" + props.nombre}
              type="number"
              onChange={compraHandler}
              required
              value={compra}
            />
          </Col>
          <Col className="mb-6">
            <Form.Label className="mb-2 ">Venta</Form.Label>
            <Form.Control
              name={"Vlv" + props.nombre}
              type="number"
              onChange={ventaHandler}
              required
              value={venta}
            />
          </Col>
          <Col className="mb-6">
            <Form.Label className="mb-2 ">Utilidad</Form.Label>
            <Form.Control
              name={"Vlu" + props.nombre}
              type="number"
              disabled
              value={utilidad}
            />
          </Col>
        </Row>
      </Form.Group>
      <Row>
        <Col>&nbsp;</Col>
      </Row>
    </>
  );
}
export default CantidadContenedores;
