import React from "react";
import Table from "react-bootstrap/Table";


const Tabla = ({ datos }) => {
    


  return (
    <>
      <Table>
        <thead>
          <tr>
            <td>a</td>
            <td>b</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              llll
            </td>
            <td>
              kjhk
            </td>
          </tr>
        </tbody>
      </Table>
      <button >Exportar a Excel</button>
    </>
  );
};

export default Tabla;
