import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import Default from "./views/Default";

function App() {
  return (
    <Default />
  );
}
export default App;
