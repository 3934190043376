import Container from "react-bootstrap/Container";
export default function Pie() {
  return (
    <footer className="footer">
      <Container>
        <span className="text-muted">
          Powered by Coltegra &copy; {new Date().getFullYear()}
        </span>
      </Container>
    </footer>
  );
}
